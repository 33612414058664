<template>
  <BaseInput
    :value="values[field.id]"
    :mask="{
      ...(field.properties.basic.currencySymbol || {}),
      ...(field.properties.basic.format || {}),
      tailPrefix: !!field.properties.basic.tailPrefix
    }"
    :type="field.properties.basic.currencySymbol || field.properties.basic.format ? 'text' : 'number'"
    size="is-small"
    :disabled="field.properties.basic.isDisabled"
    :controls="field.properties.basic.showControls"
    :placeholder="field.properties.basic.hasPlaceholder ? field.properties.basic.placeholder : ''"
    @input="handleInput($event)"
    @blur="handleBlur()"
    v-on="fieldEvents"
  />
</template>
  
<script >
import BaseInput from '@/components/generics/BaseInput.vue';
const __sfc_main = {};
__sfc_main.props = {
  field: {
    type: Object,
    required: true
  },
  values: {
    type: Object,
    required: true
  },
  errors: {
    type: Object,
    required: true
  },
  fieldEvents: {
    type: Object,
    default: () => ({})
  },
  customEventHandler: {
    type: Function,
    default: () => {}
  }
};
__sfc_main.setup = (__props, __ctx) => {
  const props = __props;
  const emit = __ctx.emit;
  const handleBlur = () => {
    emit('blur');
  };
  const handleInput = value => {
    emit('update', {
      value
    });
    if (props.customEventHandler) {
      props.customEventHandler();
    }
  };
  return {
    handleBlur,
    handleInput
  };
};
__sfc_main.components = Object.assign({
  BaseInput
}, __sfc_main.components);
export default __sfc_main;
</script>
